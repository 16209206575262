import React, { Fragment, useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Card } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../constants/constants";
import Loader from "react-loader-spinner";
import DropdownMenuGroup from "../helper/top_dropdown_with_phase";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";

const DATA_TABLE_URL = baseURL + "disbursements";
const DATA_TABLE_CASH_URL = baseURL + "disbursements-report";
const DATA_TABLE_DOWNLOAD_URL = baseURL + "disbursements-download";
const DATA_TABLE_DOWNLOAD_CASH_URL = baseURL + "disbursements-report-download";

const FilterComponent = ({
  filterText,
  onFilter,
  searchClick,
  dateFrom,
  dateTo,
  onDateFromChange,
  onDateToChange,
  downloadDatas,
}) => (
  <>
    <div className="row m-1">
      <div className="col-7">
        <div className="input-group ">
          <span className="input-group-text ml-10">Date From</span>
          <input
            type="date"
            className="form-control form-control-md form-control-solid"
            value={dateFrom}
            onChange={onDateFromChange}
          />
          <span className="input-group-text ml-5">Date To</span>
          <input
            type="date"
            className="form-control  form-control-md form-control-solid"
            value={dateTo}
            onChange={onDateToChange}
          />
        </div>
      </div>
      <div className="col-4">
        <Paper
          style={{
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 300,
          }}
        >
          <InputBase
            style={{
              marginLeft: 8,
              flex: 1,
            }}
            placeholder="Search By Outlet Code"
            inputProps={{ "aria-name": "Search By Outlet Code" }}
            value={filterText}
            onChange={onFilter}
          />
          <Divider
            style={{
              width: 1,
              height: 28,
              margin: 4,
            }}
          />
          <IconButton
            style={{ padding: 10 }}
            aria-name="Search"
            onClick={searchClick}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <div className="col-1">
        <button
          id="dl_excel"
          title="Download as Excel"
          className="btn btn-success mr-5"
          onClick={downloadDatas}
        >
          <i className="la la-file-excel"></i>
        </button>
      </div>
    </div>
  </>
);

function Disbursements(props) {
  const userType = localStorage.getItem("cr_user_type");
  const fiID = localStorage.getItem("fi_id");

  const formatDate = (date) => {
    var d = new Date();
    if (date) {
      d = new Date(date);
    }
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const [headers, setHeaders] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;

  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [dpids, setDpids] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);
  const [phases, setPhases] = useState([]);
  const [selectedType, setSelectedType] = useState({
    label: "Credit",
    value: 1,
  });

  const creditOption = [
    { label: "Credit", value: 1 },
    { label: "Cash", value: 2 },
  ];

  const columns = React.useMemo(() => {
    const commonColumns = [
      {
        name: "House",
        selector: "dh_name",
        sortable: true,
      },
      {
        name: "Point",
        selector: "dp_name",
        sortable: true,
      },
      {
        name: "Outlet Code",
        selector: "outlet_code",
        sortable: true,
      },
      {
        name: "Outlet Name",
        selector: "outlet_name",
        sortable: true,
      },
      {
        name: "Owner Name",
        selector: "owner_name",
        sortable: true,
      },
      {
        name: "Phone",
        selector: "phone",
        sortable: true,
      },
      {
        name: "Address",
        selector: "address",
        sortable: true,
      },
      {
        name: "Disbursed Cr Amount",
        selector: "credit_amount",
        sortable: true,
      },
      {
        name: "Invoice Amount",
        selector: "invoice_amount",
        sortable: true,
      },
      {
        name: "Cash Amount",
        selector: "cash_payment",
        sortable: true,
      },
      {
        name: "Disbursed Cash Amount",
        selector: "cash_amount",
        sortable: true,
      },
      {
        name: "Credit Disbursed",
        selector: "total_amount",
        sortable: true,
      },
      {
        name: "Total Disbursed",
        selector: "total_amount",
        sortable: true,
      },
      {
        name: "Date",
        selector: "sys_date",
        sortable: true,
      },
    ];

    if (selectedType.value == 2) {
      return commonColumns.filter(
        (column) =>
          column.name !== "Cash Amount" && column.name !== "Invoice Amount"
      );
    } else {
      return commonColumns;
    }
  }, [selectedType]);

  const filteredColumns = columns.filter((column) => {
    if (userType == "fi" && fiID == 5) {
      return ![
        "Disbursed Cr Amount",
        "Disbursed Cash Amount",
        "Invoice Amount",
        "Cash Amount",
      ].includes(column.name);
    } else if (userType != "fi" && fiID != 5) {
      return !["Credit Disbursed"].includes(column.name);
    }
    return true;
  });

  const getReportData = async () => {
    try {
      if (dpids.length > 0 && phases.length > 0) {
        setIsLoading(true);
        let downloadUrl = DATA_TABLE_URL;
        if (selectedType.value == 2) {
          downloadUrl = DATA_TABLE_CASH_URL;
        }
        let res = await axios.post(
          `${downloadUrl}?page=${page}&per_page=${countPerPage}`,
          { dpids, phases, filterText, dateFrom, dateTo }
        );

        if (res?.data?.success) {
          setData(res.data.data);
          setTotalRows(res.data.data.pagination.total);
          setHeaders(filteredColumns);
          setTotalAmt(res?.data?.data?.total_amount);
        } else {
          setData({});
          setTotalRows(0);
          setHeaders([]);
          setTotalAmt(0);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCashReportData = async () => {
    try {
      if (dpids.length > 0 && phases.length > 0) {
        setIsLoading(true);
        let res = await axios.post(
          `${CASH_DATA_TABLE_URL}?page=${page}&per_page=${countPerPage}`,
          { dpids, phases, filterText, dateFrom, dateTo }
        );

        if (res?.data?.success) {
          setCashData(res.data.data);
          setCashTotalRows(res.data.data.pagination.total);
          setCashHeaders(cashColumns);
          setCashTotalAmt(res.data.data.total_amount);
        } else {
          setCashData({});
          setCashTotalRows(0);
          setCashHeaders([]);
          setCashTotalAmt(0);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const downloadDatas = async () => {
    var token = localStorage.getItem("token");
    let downloadUrl = DATA_TABLE_DOWNLOAD_URL;
    if (selectedType.value == 2) {
      downloadUrl = DATA_TABLE_DOWNLOAD_CASH_URL;
    }
    setIsDownloading(true);
    await axios
      .post(
        downloadUrl,
        { dpids, phases, filterText, dateFrom, dateTo },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            // window.open(item, '_blank', 'noopener,noreferrer')
            let link = window.document.createElement("a");
            link.setAttribute("href", `${item}`);
            link.setAttribute("download", "scope_outlet");
            link.setAttribute("target", "_blank");
            link.click();
          });
        }
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);
      });
  };
  const handleDpidChange = (ids) => {
    setDpids(ids);
  };

  const handlePhaseChange = (ids) => {
    setPhases(ids);
  };

  const searchClick = () => {
    getReportData();
  };

  const subHeader = useMemo(() => {
    return (
      <Fragment>
        <FilterComponent
          searchClick={searchClick}
          onFilter={(e) => {
            setFilterText(e.target.value);
          }}
          filterText={filterText}
          dateFrom={dateFrom !== "" ? dateFrom : formatDate()}
          dateTo={dateTo !== "" ? dateTo : formatDate()}
          onDateFromChange={(e) => {
            setDateFrom(e.target.value);
          }}
          onDateToChange={(e) => {
            setDateTo(e.target.value);

            // getReportData();
          }}
          downloadDatas={downloadDatas}
        />
      </Fragment>
    );
  }, [filterText, dpids, phases, dateFrom, dateTo, selectedType]);

  useEffect(() => {
    getReportData();
  }, [page, dpids, phases, dateTo, selectedType]);

  const handleTypeChange = (val) => {
    setSelectedType(val);
  };

  return (
    <Card className="m-5">
      <Card.Header>
        <div className="row">
          <h3 className="card-title">Disbursements Day Wise</h3>
        </div>
      </Card.Header>
      <Card.Body>
        <DropdownMenuGroup
          onDpidChange={handleDpidChange}
          onPhaseChange={handlePhaseChange}
          isSearch={true}
          isLoading={isLoading}
        />
        {isDownloading ? (
          <div>
            <div style={{ textAlign: "center" }}>
              <Loader type="Rings" color="#00BFFF" height={100} width={100} />
            </div>
          </div>
        ) : null}
        {isLoading ? (
          <div>
            <div style={{ textAlign: "center" }}>
              <Loader type="Rings" color="#00BFFF" height={100} width={100} />
            </div>
          </div>
        ) : (
          <DataTable
            noHeader
            columns={headers}
            data={data.data}
            highlightOnHover
            pagination
            paginationServer
            subHeader
            subHeaderComponent={subHeader}
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
          />
        )}
      </Card.Body>
      <Card.Footer>
        <span className="font-size-h3 font-weight-boldest ml-5">
          Total Disbursed Amount: {totalAmt || 0}
        </span>
      </Card.Footer>
    </Card>
  );
}
export default Disbursements;
