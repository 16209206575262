import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../constants/constants";
import Loader from "react-loader-spinner";
import DropdownMenuGroup from "../helper/top_dropdown";
import { useAlert } from "react-alert";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import "react-confirm-alert/src/react-confirm-alert.css";
import swal from "sweetalert";

const DATA_TABLE_URL = baseURL + "credit-summary-of-outlets";
const DATA_TABLE_DOWNLOAD_URL = baseURL + "credit-summary-of-outlets-download";

const FilterComponent = ({
  filterText,
  onFilter,
  searchClick,
  handleDownloadExcel,
}) => (
  <>
    <Paper
      style={{
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 300,
      }}
    >
      <InputBase
        style={{
          marginLeft: 8,
          flex: 1,
        }}
        placeholder="Search By Outlet Code"
        inputProps={{ "aria-label": "Search By Outlet Code" }}
        value={filterText}
        onChange={onFilter}
      />
      <Divider
        style={{
          width: 1,
          height: 28,
          margin: 4,
        }}
      />
      <IconButton
        style={{ padding: 10 }}
        aria-name="Search"
        onClick={searchClick}
      >
        <SearchIcon />
      </IconButton>
    </Paper>{" "}
    {localStorage.getItem("cr_user_type") != "fi" && (
      <button
        className="btn btn-clean btn-success ml-2"
        style={{ backgroundColor: "green" }}
        onClick={() => handleDownloadExcel()}
        data-toggle="tooltip"
        data-placement="bottom"
        title="Download report as excel"
      >
        <i className="far fa-file-excel" />
      </button>
    )}
  </>
);

function CreditSummeryOfOutletsReport(props) {
  const userType = localStorage.getItem("cr_user_type");
  const fiID = localStorage.getItem("fi_id");
  const alert = useAlert();
  const [headers, setHeaders] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resData, setResData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;

  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dpids, setDpids] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Point",
      selector: "point_name",
      sortable: true,
    },
    {
      name: "Outlet Code",
      selector: "outlet_code",
    },
    {
      name: "Outlet Name",
      selector: "outlet_name",
      sortable: true,
    },
    {
      name: "Owner Name",
      selector: "owner_name",
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Cr. Amount",
      selector: "credit_amount",
      sortable: true,
    },
    {
      name: "Allowed Limit",
      selector: "allowed_limit",
      sortable: true,
    },
    {
      name: "Daily Limit",
      selector: "daily_limit",
      sortable: true,
    },
    {
      name: "Current Balance",
      selector: "current_balance",
      sortable: true,
    },
    {
      name: "Cr Due Amount",
      selector: "credit_due",
      sortable: true,
    },
    {
      name: "Cash Due Amount",
      selector: "cash_due",
      sortable: true,
    },
    {
      name: "Total Due",
      selector: "total_due",
      sortable: true,
    },
    {
      name: "Cr Minimum Due",
      selector: "minimum_due",
      sortable: true,
    },
    {
      name: "Cash Minimum Due",
      selector: "cash_minimum_due",
      sortable: true,
    },
    {
      name: "Cr Carry Amount",
      selector: "carry_amount",
      sortable: true,
    },
    {
      name: "Cash Carry Amount",
      selector: "cash_carry_amount",
      sortable: true,
    },
    // add new line

    {
      name: "Minimum Due",
      selector: "minimum_due",
      cell: (row) => {
        return (
          parseFloat(row.minimum_due || 0) +
          parseFloat(row.cash_minimum_due || 0)
        );
      },
    },
    {
      name: "Carry Amount",
      selector: "carry_amount",
      cell: (row) => {
        return (
          parseFloat(row.carry_amount || 0) +
          parseFloat(row.cash_carry_amount || 0)
        );
      },
    },
    // new column ends
    {
      name: "FI Name",
      selector: "fi_name",
      sortable: true,
    },
    {
      name: "Acc. No.",
      selector: "acc_no",
      sortable: true,
    },
  ];

  const filteredColumns = columns.filter((column) => {
    if (userType == "fi" && fiID == 5) {
      return ![
        "Cr Due Amount",
        "Cash Due Amount",
        "Cr Minimum Due",
        "Cash Minimum Due",
        "Cr Carry Amount",
        "Cash Carry Amount",
      ].includes(column.name);
    } else if (userType != "fi" && fiID != 5) {
      return !["Carry Amount", "Minimum Due"].includes(column.name);
    }
    return true;
  });

  const getReportDatas = async () => {
    try {
      if (dpids.length > 0) {
        setIsLoading(true);

        let res = await axios.post(
          `${DATA_TABLE_URL}?page=${page}&per_page=${countPerPage}`,
          { dpids, filterText }
        );

        if (res?.data?.success) {
          const tempResponse = res?.data?.data;
          console.log(tempResponse, "tempResponse");

          setResData(tempResponse);
          setTotalRows(tempResponse?.pagination?.total);
          setHeaders(filteredColumns);
        } else {
          setResData({});
          setTotalRows(0);
          setHeaders([]);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setResData({});
      setTotalRows(0);
      setHeaders([]);
      console.log(error);
    }
  };

  const handleDownloadExcel = () => {
    swal({
      icon: "load.gif",
      buttons: false,
    });
    axios
      .post(`${DATA_TABLE_DOWNLOAD_URL}`, { dpids, filterText })
      .then((res) => {
        swal.close();
        if (res.data.success) {
          alert.success(res.data.message);
          window.open(res?.data?.data, "_blank", "noopener,noreferrer");
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        swal.close();
        alert.error(err.message);
      });
  };

  const handleDpidChange = (ids) => {
    setDpids(ids);
  };

  useEffect(() => {
    getReportDatas();
  }, [dpids, page]);

  const searchClick = () => {
    getReportDatas();
  };

  const subHeader = React.useMemo(() => {
    return (
      <Fragment>
        <FilterComponent
          searchClick={searchClick}
          onFilter={(e) => {
            setFilterText(e.target.value);
          }}
          filterText={filterText}
          handleDownloadExcel={handleDownloadExcel}
        />
      </Fragment>
    );
  }, [filterText, dpids]);

  return (
    <Card className="m-5">
      <Card.Header>
        <div className="row">
          <h3 className="card-title">Credit Summary of Outlets</h3>
        </div>
      </Card.Header>

      <Card.Body>
        <DropdownMenuGroup
          onDpidChange={handleDpidChange}
          isSearch={true}
          isLoading={isLoading}
        />

        {isLoading ? (
          <div>
            <div style={{ textAlign: "center" }}>
              <Loader type="Rings" color="#00BFFF" height={100} width={100} />
            </div>
          </div>
        ) : (
          <DataTable
            noHeader
            columns={headers}
            data={resData?.data}
            highlightOnHover
            pagination
            paginationServer
            subHeader
            subHeaderComponent={subHeader}
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => setPage(page)}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default CreditSummeryOfOutletsReport;
